import * as React from "react"
import { graphql } from 'gatsby'

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import { Page } from 'components/core/layouts/base';
import { Hero } from 'components/case-study/hero';
import { CasesList } from 'components/case-study/content';
import { Footer } from 'components/core/footer';
import { ContactCaseStudy } from 'components/core/contact';

function IndexPage({data, location}){
  const {t} = useTranslation("case-study");
  return <Page dark={false} location={location} title={t("metadata.title")} description={t("metadata.description")}>
          <Hero/>
          <CasesList values={data.allMdx.edges}/>
          <ContactCaseStudy/>
          <Footer/>
         </Page>
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMdx(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {frontmatter: {hidden: {ne: true}}, fields: {source: {eq: "case-study"}, lang: {eq: $language}}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            author
            date
            frontImg {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
          excerpt(pruneLength: 200)
          fields {
            source
            slug
            path
          }
        }
      }
    }
  }
`;